<template>
  <v-container fluid>
    <v-tabs>
      <v-tab> {{ $t("settings.userSettings") }} </v-tab>
      <v-tab :disabled="!permitted('CompanySettings.View')">
        {{ $t("accessToken.title") }}
      </v-tab>
      <v-tab :disabled="!permitted('GlobalSettings.View')">
        {{ $t("settings.global.name") }}
      </v-tab>
      <v-tab :disabled="!permitted('CompanySettings.View')">
        {{ $t("settings.company.title") }}
      </v-tab>
      <v-tab :disabled="!permitted('Mqtt.View')">{{
        $t("mqtt.settingsTabTitle")
      }}</v-tab>
      <v-tab :disabled="!permitted('CamOnline.DeleteServer', '')">{{
        $t("camOnline.tabTitle")
      }}</v-tab>
      <v-tab-item>
        <v-form
          :lazy-validation="true"
          @submit.prevent="handleSubmit"
          ref="form"
          class="px-5"
        >
          <!-- Create field for theme-->
          <v-select
            v-model="setting.theme"
            :items="themes"
            :placeholder="$t('settings.themes')"
            :label="$t('settings.themes')"
            :hide-details="false"
            class="pt-5"
          ></v-select>

          <v-switch
            :label="$t('settings.data.toolbarTransparancy')"
            v-model="setting.toolbarTransparent"
          ></v-switch>
          <v-switch
            :label="$t('settings.data.stickySideMenu')"
            v-model="setting.stickySideMenu"
          ></v-switch>
          <v-btn x-large color="primary" type="submit">{{
            $t("common.save")
          }}</v-btn>
        </v-form>

        <!-- license information section for admins what -->
        <v-card class="mt-5" flat>
          <v-divider></v-divider>
          <v-card-title class="ml-1"> License </v-card-title>
          <v-card-text class="ml-1 font-weight-medium">
            <div class="subtitle-1 font-weight-medium green--text" align="left">
              {{ $t("common.active") }}
            </div>
            <v-row no-gutters>
              <v-col align="left">
                <div class="subtitle-1 font-weight-medium">
                  {{ $t("license.licensee") }}
                </div>
                <div>
                  {{ $t("license.fields.name") + ": " + this.license.name }}
                </div>
                <div>
                  {{ $t("license.fields.email") + ": " + this.license.email }}
                </div>
                <div v-if="whiteLabel">
                  {{
                    $t("license.fields.whiteLabel") +
                    ": " +
                    (whiteLabel ? $t("common.yes") : $t("common.no"))
                  }}
                </div>
              </v-col>
              <v-col align="left">
                <div class="subtitle-1 font-weight-medium">
                  {{ $t("license.coveredFeatures") }}
                </div>
                <div>
                  {{
                    $t("license.fields.maxCompanies") +
                    ": " +
                    this.license.SO_MAX_COMPANIES
                  }}
                </div>
                <div>
                  {{
                    $t("license.fields.decoderIncluded") +
                    ": " +
                    (this.license.DECODER_ACTIVE
                      ? $t("common.yes")
                      : $t("common.no"))
                  }}
                </div>
              </v-col>
              <v-spacer />
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text> </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <access-tokens v-if="permitted('CompanySettings.View')" />
      </v-tab-item>
      <v-tab-item>
        <global-settings />
      </v-tab-item>

      <v-tab-item>
        <company-settings />
      </v-tab-item>

      <v-tab-item>
        <broker-list />
      </v-tab-item>

      <v-tab-item>
        <cam-online-list />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { Roles } from "@/_helpers/Role";
import { mapActions, mapState } from "vuex";
import AccessTokens from "../accessToken/AccessTokens.vue";
import GlobalSettings from "./GlobalSettings.vue";
import CompanySettings from "./CompanySettings.vue";
import BrokerList from "../mqtt/BrokerList.vue";
import CamOnlineList from "../camonline/CamOnlineList.vue";

export default {
  name: "SettingEdit",
  data() {
    return {
      theme: "Scientific",
      themes: ["Scientific", "Colors"],
      setting: {
        theme: "Scientific",
        toolbarTransparent: true,
        stickySideMenu: true,
      },
    };
  },

  async created() {
    await this.getUserSettings();

    if (Object.keys(this.userSettings).length < 0) {
      await this.setUserSettings(this.setting);
    }

    this.setting = this.userSettings;

    // Retrieve license info if user is an admin
    if (this.userIsAdmin) await this.getLicenseTerms();
  },

  methods: {
    ...mapActions("configuration", [
      "getUserSettings",
      "setUserSettings",
      "getLicenseTerms",
    ]),

    async handleSubmit() {
      await this.setUserSettings(this.setting);
      this.setting = this.userSettings;
    },
  },

  computed: {
    ...mapState("configuration", ["userSettings", "license"]),
    ...mapState("users", ["currentUser"]),

    userIsAdmin() {
      return this.currentUser.role == "SuperAdmin";
    },

    Roles() {
      return Roles;
    },
  },

  components: {
    AccessTokens,
    CamOnlineList,
    GlobalSettings,
    CompanySettings,
    BrokerList,
  },
};
</script>